import React from 'react'
import {graphql, Link} from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../pages/style.css'

class BlogPostTemplate extends React.Component {
    render() {
        const post = (this.props.data && this.props.data.markdownRemark) || ''
        const siteTitle = (this.props.data && this.props.data.site.siteMetadata.title) || ''
        const {previous, next} = this.props.pageContext

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title={post.frontmatter.title} description={post.excerpt}/>
                <h1>{post.frontmatter.title}</h1>
                <p className="secondary-text">
                    {post.frontmatter.date}
                </p>
                <div dangerouslySetInnerHTML={{__html: post.html}}/>

                <Bio/>

                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <h3>
                        <li>
                            {previous && (
                                <Link to={previous.fields.slug} rel="prev">
                                    ← {previous.frontmatter.title}
                                </Link>
                            )}
                        </li>
                    </h3>
                    <li>
                        {next && (
                            <h3><Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                            </h3>
                        )}
                    </li>
                </ul>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
